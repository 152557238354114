import ModalPopup from '@/shared/components/ModalPopup.vue';
import { defineComponent, ref, reactive, watch } from '@vue/composition-api';
import { useAllUserBookingsQuery, BookingStatus } from '@/generated-types/graphql.types';
import { dateDSTCheck } from '@/util/dates';
export default defineComponent({
    components: {
        ModalPopup
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const bookingsQueryOptions = reactive({
            enabled: false
        });
        const { refetch, onResult } = useAllUserBookingsQuery(bookingsQueryOptions);
        const bookings = ref([]);
        const bookingsLoaded = ref(false);
        /**
         * @description
         * Watcher of the useAllUserBookingsQuery.
         * If the results are still loading, do nothing.
         * When the results are loaded from the server, show the bookings select with options.
         */
        onResult((result) => {
            if (result?.loading)
                return;
            bookings.value =
                result.data?.get_bookings
                    .filter(booking => booking &&
                    booking.booking_status === BookingStatus.Pending &&
                    !booking.is_afterpay_requested)
                    .map(booking => {
                    return {
                        label: `${booking.space.space_name}, ${dateDSTCheck(booking.slot_start, globalThis.$timezone, 'DD-MM-YYYY')} (${dateDSTCheck(booking.slot_start, globalThis.$timezone, 'HH:mm')}-${dateDSTCheck(booking.slot_end, globalThis.$timezone, 'HH:mm')})`,
                        value: booking.booking_id
                    };
                }) || [];
            bookingsLoaded.value = true;
        });
        const selectedBookingId = ref(undefined);
        watch(() => props.value, () => {
            bookingsQueryOptions.enabled = props.value;
            if (props.value && bookingsQueryOptions.enabled) {
                refetch();
            }
        }, { immediate: true });
        return {
            bookings,
            bookingsLoaded,
            selectedBookingId,
            dateDSTCheck
        };
    }
});
